<template>
    <div class="right-menu">
        
        <subHeader pageName="DOMAIN SETUP"/>

        <tutorialsAction moduleName="DOMAIN SETUP"/>

        <div class="db-container">
            <div class="messagebr">
                <p><b>Enhance Your Brand with a Custom Domain:</b></p>
                <p>After logging into your domain provider (i.e GoDaddy, Namecheap, Bluehost, Siteground etc.),</p>
                <p>create <b>A record</b> using the provided "<u>Hosting IP Address</u>".</p>
                <p>This simple step ensures your custom domain seamlessly directs visitors to your new online store.<br><br>
                Should you require assistance during this process, our dedicated support team is ready to guide you. </p>
            </div>
            <div class="domainWhiteBox">
                <div class="domainWhiteBoxTitle">
                    <h3>New Domain</h3>
                </div>
                <ValidationObserver ref="domainObserver" v-slot="{ handleSubmit }">
                    <div class="domainForm">
                        <form>
                            <ValidationProvider 
                                name="domain" 
                                :rules="{required:true,regex:/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/}" 
                                v-slot="{ errors }" 
                            >
                                <div class="domainInputwrap">
                                    <input type="text" name="domainName" id="new-domain" placeholder="Write a domain name" v-model="domain" :disabled="tourIsActive">
                                    <span class="text-danger" v-if="errors">{{ errors[0] ? errors[0] + ' ' + '(format to follow "something.com") ' :''}}</span>
                                    <p>Please use the full path domain, for example my-domain.com or my-subdomain.com if you are asking for subdomain</p>
                                </div>
                            </ValidationProvider>
                            <loaderBtn v-if="submitLoader"/>
                            <button @click.prevent="handleSubmit(submitDomain)" id="connect-btn" :disabled="tourIsActive" v-else>Connect</button>
                        </form>
                    </div>
                </ValidationObserver>
            </div>
            <div class="domainWhiteBox mb-0" id="domain-instructions">
                <div class="domainWhiteBoxTitle domainInstructions">
                    <h3>Domain Instructions</h3>
                    <p>Your custom DNS records for YeetCommerce</p>
                </div>
                <div class="domainInstructionsDetail">
                    <div class="InstructionsDetailList">
                        <div class="InstructionsDetailListTxt">
                            <p>Domain:</p>
                        </div>
                        <div class="InstructionsDetailListTxt2">
                            <p>{{setting.secondary_domain ? setting.secondary_domain : setting.domain}}</p>
                        </div>
                    </div>
                    <div class="InstructionsDetailList">
                        <div class="InstructionsDetailListTxt">
                            <p>Account Plan:</p>
                        </div>
                        <div class="InstructionsDetailListTxt2">
                            <p>{{setting.subscription ? setting.subscription.package.name : ''}}</p>
                        </div>
                    </div>
                    <div class="InstructionsDetailList">
                        <div class="InstructionsDetailListTxt">
                            <p>Hosting IP Address:</p>
                        </div>
                        <div class="InstructionsDetailListTxt2">
                            <p>{{ setting ? setting.machine_ip : ''}} (real IP Address)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- vue tour -->
        <v-tour name="domainSetupTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>
<script>
import subHeader from "@/components/subHeader.vue";
import { mapGetters } from 'vuex'
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    metaInfo() {
        return {
            title: 'Settings | Domains | YeetCommerce',
        };
    },
    data() {
        return {
            master_base_url: process.env.VUE_APP_MASTER_BASE_URL,
            domain:'',
            submitLoader:false,
            //vue-tour
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //domain setup steps
                {
                    target: '#domain-instructions',
                    content: `<strong>Domain Instructions</strong><br>You have to configure your DNS according to the given information.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'top'
                    }
                },
                {
                    target: '#new-domain',
                    content: `<strong>New Domain</strong><br>After completing configuration, you have to enter the domain to which you want to switch.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'bottom'
                    }
                },
                {
                    target: '#connect-btn',
                    content: `<strong>Connect Domain</strong><br>After entering the valid domain, you have to click this button to start the connection process.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement:'right'
                    }
                },
            ],
            tourIsActive:false
        }
    },
    components: {
        subHeader,
        tutorialsAction,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            setting: 'settings_module/settings'
        }),
    },
    methods: {
        async submitDomain(){

            //package feature checking start
            let linkChangeValid = true;

            let tempPackageFeatures = JSON.parse(this.setting.subscription.package.features)

            tempPackageFeatures.forEach((feature) => {

                if(feature.title == 'Link your custom domain'){

                    if(feature.custom_domain_linking != 1){

                        let subscriptionPath = window.location.origin + "/subscription";

                        let errorMessage = "Your subscription plan doesn't support changing the domain. "+
                        `<a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: errorMessage,
                        });

                        linkChangeValid = false;

                        return

                    }

                }

            });
            //package feature checking end

            if(linkChangeValid){

                this.submitLoader = true;

                let formData = new FormData();

                formData.append('new_domain',this.domain);

                try{
                    let domainRes = await this.$axios.post('updateDomain',formData);
                    if(domainRes.data.status_code == "1117"){
                        
                        this.$notify({
                            type: 'success',
                            title: 'Success',
                            message: res.data.message,
                        });
                        
                        this.$store.dispatch('settings_module/fetchStoreSetting');

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.error.error){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error.error,
                            });

                        }else if(error.response.data.error.includes("doesn't support")){

                            let subscriptionPath = window.location.origin + "/subscription";

                            this.$message({
                                type: 'error',
                                showClose: true,
                                dangerouslyUseHTMLString: true,
                                message: error.response.data.error 
                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.submitLoader = false;
                    this.domain = '';
                    this.$refs.domainObserver.reset();

                }

            }

        },
        //tour methods
        startDomainSetupTour(){

            this.$tours.domainSetupTour.start();

            this.tourIsActive = true;

        },
        handleNextStep(currentStep){
        },
        handlePreviousStep(currentStep){
        },
        handleTourStop(){

            this.tourIsActive = false;

        },
        handleTourFinish(){

            this.tourIsActive = false;

        },
        handleTourSkip(){
            
            this.tourIsActive = false;

        },
    },
};
</script>